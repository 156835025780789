import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "components/layout"
import Seo from "components/seo"
import { Loader } from "components/anti/loader/loader"

import { LangContext } from "../context/lang-context"

import { HomeCover } from "components/shift/home/cover"
import { HomeAbout } from "components/shift/home/about"

import { AboutValues } from "components/shift/about/values"
import { AboutTeam } from "components/shift/about/team"
import { AboutRecognitions } from "components/shift/about/recognitions"
import { AboutRequest } from "components/shift/about/request"
import { HomePracticesAreas } from "components/shift/home/practices-areas"
import { HomeResources } from "../components/shift/home/resources"

const Index = () => {
  const { lang } = useContext(LangContext)
  const data = useStaticQuery(GET_HOME_DETAILS)

  const aboutBlocks =
    lang === "EN"
      ? data?.wordPress?.aboutPage?.aboutPage
      : data?.wordPress?.aboutPage?.translation?.aboutPage ||
      data?.wordPress?.aboutPage?.aboutPage

  const homeBlocks =
    lang === "EN"
      ? data?.wordPress?.homePage?.homePage
      : data?.wordPress?.homePage?.translation?.homePage ||
      data?.wordPress?.homePage?.homePage

  const newestResources = data?.wordPress?.newestResources?.nodes
  const practiceList = data?.wordPress?.practices?.nodes
  const featuredAwards = data?.wordPress?.aboutPage?.aboutPage.awards

  return (
    <>
      {/* <Loader variant="logo" effect="fadeOut" theme="light" /> */}

      <Layout>
        <Seo title="Home" />
        <HomeCover data={homeBlocks.cover} />
        <HomeAbout data={homeBlocks.about} />
        <HomePracticesAreas
          data={homeBlocks.practices}
          list={practiceList}
          lang={lang}
        />
        <AboutValues data={aboutBlocks.values} />
        <AboutTeam data={aboutBlocks.team} />
        <AboutRecognitions data={featuredAwards} text={aboutBlocks.awards} />
        <HomeResources data={newestResources} lang={lang} />
        <AboutRequest data={aboutBlocks.request} />
      </Layout>
    </>
  )
}

export default Index

const GET_HOME_DETAILS = graphql`
  query Home {
    wordPress {
      homePage: page(id: "index", idType: URI) {
        homePage {
          cover {
            title
            text
            image {
              sourceUrl
            }
            buttons {
              url
              text
            }
          }
          about {
            title
            text
            label
            image {
              sourceUrl
            }
            button {
              url
              text
            }
          }
          practices {
            title
            text
            label
            button {
              url
              text
            }
          }
        }
        blocks {
          name
          ... on WordPress_AcfCoverMainBlock {
            attributes {
              className
            }
            coverMain {
              title
              text
              label
              imgoverlay
              buttons {
                text
                link {
                  url
                }
              }
              img {
                sourceUrl
              }
            }
          }
          ... on WordPress_AcfCoverMainAltBlock {
            coverMainAlt {
              title
              text
              label
            }
          }
          ... on WordPress_AcfTextWImageBlock {
            textWImage {
              subtitle
              title
              text
              img {
                sourceUrl
              }
              buttons {
                text
                link {
                  url
                  target
                }
              }
            }
          }
          ... on WordPress_AcfCoverContentBlock {
            attributes {
              className
            }
            coverContent {
              title
              text
              imgOverlay
              img {
                sourceUrl
              }
              buttons {
                text
                link {
                  url
                  target
                }
              }
            }
          }
          ... on WordPress_AcfIconWTextListBlock {
            attributes {
              className
            }
            iconWTextList {
              text
              title
              subtitle
              buttons {
                text
                link {
                  url
                }
              }
              list {
                title
                text
                icon {
                  sourceUrl
                }
              }
            }
          }
          ... on WordPress_AcfCardListBlock {
            attributes {
              className
            }
            cardList {
              text
              subtitle
              title
              cards {
                title
                text
                img {
                  sourceUrl
                }
              }
            }
          }
          ... on WordPress_AcfTextBubbleWImageBlock {
            textBubbleWImage {
              text
              title
              subtitle
              img {
                sourceUrl
              }
            }
          }
          ... on WordPress_AcfCoverContentAltBlock {
            attributes {
              className
            }
            coverContentAlt {
              title
              text
              imgOverlay
              img {
                sourceUrl
              }
              buttons {
                text
                link {
                  url
                }
              }
            }
          }
          ... on WordPress_AcfCardListColAltBlock {
            cardListColAlt {
              title
              text
              subtitle
              cards {
                subtitle
                text
                title
                img {
                  sourceUrl
                }
              }
              buttons {
                text
                link {
                  url
                }
              }
            }
          }
        }
        translation(language: ID) {
          homePage {
            cover {
              title
              text
              image {
                sourceUrl
              }
              buttons {
                url
                text
              }
            }
            about {
              title
              text
              label
              image {
                sourceUrl
              }
              button {
                url
                text
              }
            }
            practices {
              title
              text
              label
              button {
                url
                text
              }
            }
          }
          blocks {
            name
            ... on WordPress_AcfCoverMainBlock {
              attributes {
                className
              }
              coverMain {
                title
                text
                label
                imgoverlay
                buttons {
                  text
                  link {
                    url
                  }
                }
                img {
                  sourceUrl
                }
              }
            }
            ... on WordPress_AcfCoverMainAltBlock {
              coverMainAlt {
                title
                text
                label
              }
            }
            ... on WordPress_AcfTextWImageBlock {
              textWImage {
                subtitle
                title
                text
                img {
                  sourceUrl
                }
                buttons {
                  text
                  link {
                    url
                    target
                  }
                }
              }
            }
            ... on WordPress_AcfCoverContentBlock {
              attributes {
                className
              }
              coverContent {
                title
                text
                imgOverlay
                img {
                  sourceUrl
                }
                buttons {
                  text
                  link {
                    url
                    target
                  }
                }
              }
            }
            ... on WordPress_AcfIconWTextListBlock {
              attributes {
                className
              }
              iconWTextList {
                text
                title
                subtitle
                buttons {
                  text
                  link {
                    url
                  }
                }
                list {
                  title
                  text
                  icon {
                    sourceUrl
                  }
                }
              }
            }
            ... on WordPress_AcfCardListBlock {
              attributes {
                className
              }
              cardList {
                text
                subtitle
                title
                cards {
                  title
                  text
                  img {
                    sourceUrl
                  }
                }
              }
            }
            ... on WordPress_AcfTextBubbleWImageBlock {
              textBubbleWImage {
                text
                title
                subtitle
                img {
                  sourceUrl
                }
              }
            }
            ... on WordPress_AcfCoverContentAltBlock {
              attributes {
                className
              }
              coverContentAlt {
                title
                text
                imgOverlay
                img {
                  sourceUrl
                }
                buttons {
                  text
                  link {
                    url
                  }
                }
              }
            }
            ... on WordPress_AcfCardListColAltBlock {
              cardListColAlt {
                title
                text
                subtitle
                cards {
                  subtitle
                  text
                  title
                  img {
                    sourceUrl
                  }
                }
                buttons {
                  text
                  link {
                    url
                  }
                }
              }
            }
          }
        }
      }
      aboutPage: page(id: "about", idType: URI) {
        aboutPage {
          values {
            label
            text
            title
            values {
              text
              title
              image {
                sourceUrl
              }
            }
          }
          team {
            label
            text
            title
            image {
              sourceUrl
            }
            button {
              url
              text
            }
          }
          request {
            title
            text
            image {
              sourceUrl
            }
            button {
              url
              text
            }
          }
          awards {
            title
            text
            label
            button {
              url
              text
            }
            awards {
              ... on WordPress_Award {
                id
                award {
                  to
                  name
                  from
                  image {
                    sourceUrl
                  }
                }
              }
            }
          }
        }
        translation(language: ID) {
          aboutPage {
            values {
              label
              text
              title
              values {
                text
                title
                image {
                  sourceUrl
                }
              }
            }
            team {
              label
              text
              title
              image {
                sourceUrl
              }
              button {
                url
                text
              }
            }
            request {
              title
              text
              image {
                sourceUrl
              }
              button {
                url
                text
              }
            }
            awards {
              title
              text
              label
              button {
                url
                text
              }
              awards {
                ... on WordPress_Award {
                  id
                  award {
                    to
                    name
                    from
                    image {
                      sourceUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
      practices(first: 100, where: { language: EN }) {
        nodes {
          practice {
            cover {
              title
              image {
                sourceUrl
              }
              thumbnail {
                sourceUrl
              }
            }
            status {
              taxonomy {
                slug
              }
            }
          }
          translation(language: ID) {
            practice {
              cover {
                title
                image {
                  sourceUrl
                }
                thumbnail {
                  sourceUrl
                }
              }
              status {
                taxonomy {
                  slug
                }
              }
            }
          }
          slug
        }
      }
      newestResources: posts(where: { language: EN }, first: 3) {
        nodes {
          title
          slug
          featuredImage {
            node {
              sourceUrl
            }
          }
          date
          author {
            node {
              name
            }
          }
          categories {
            nodes {
              name
            }
          }
          translation(language: ID) {
            title
            featuredImage {
              node {
                sourceUrl
              }
            }
            date
            author {
              node {
                name
              }
            }
            categories {
              nodes {
                name
              }
            }
          }
        }
      }
    }
  }
`
