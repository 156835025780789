import React, { useContext } from "react"
import { useScrollAnim } from "src/components/hooks/hooks"

import { Cover } from "../../anti/cover/cover"
import { Button } from "../../anti/buttons/buttons"
import { LoadingContext } from "src/context/loading-context"

import gfx from "../../../assets/img/common/shift-graphic/grfx-grfx-floating-2-copy-2.png"

export const HomeCover = ({ data }) => {
  const { initialLoading } = useContext(LoadingContext)
  const [trigger, anim] = useScrollAnim()

  const block = data
  return (
    <div style={{ minHeight: "100vh" }}>
      {!initialLoading && (
        <div ref={trigger}>
          <Cover
            variant="basic"
            img={block.image && block.image.sourceUrl}
            imgHeight="h-700px h-md-900px"
            title={block?.title}
            // smallText={block?.smallText}
            titleClassName={`${anim(1)} h1-home`}
            contentMaxWidth="w-400px w-md-600px position-relative"
            text={block?.text}
            textClassName={`${anim(2)} mt-3 mb-4 p-home`}
            className={`cover-home animated fadeInUp`}
          >
            {block.buttons.map((button, i) => (
              <span className={`${anim(3 + i)} overflow-hidden d-block`}>
                <Button variant="link" link={button?.url} key={i}>
                  {button?.text}
                </Button>
              </span>
            ))}
          </Cover>
          <div className="gfx-wrapper">
            <img src={gfx} />
          </div>
        </div>
      )}
    </div>
  )
}
