import React from "react"
import { useScrollAnim } from "src/components/hooks/hooks"

export const AboutGraphic = () => {
  const [trigger, anim] = useScrollAnim()

  return (
    <div ref={trigger}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="489"
        height="628"
        viewBox="0 0 489 628"
        className={`graphic-1`}
      >
        <g fill="none" fill-rule="evenodd">
          <g>
            <g>
              <g>
                <path
                  stroke="#DEDEDE"
                  d="M0 309h70v127H0V309zm70 190v127H0V499h70z"
                  transform="translate(-38 -1082) translate(39 1083)"
                  className={`${anim(8)}`}
                />
                <path
                  stroke="#000"
                  d="M458 151.078v256H357.777V235.212H161v-84.134h297zM416.5 0C448.809 0 475 26.191 475 58.5S448.809 117 416.5 117 358 90.809 358 58.5 384.191 0 416.5 0z"
                  opacity=".4"
                  transform="translate(-38 -1082) translate(39 1083)"
                  className={`${anim(2)}`}
                />
              </g>
              <path
                stroke="#000"
                d="M377.845 214v99h-38.47v-66.464h-75.53V214h114zm-19.505-70c14.089 0 25.505 11.416 25.505 25.495 0 14.088-11.416 25.505-25.505 25.505-14.079 0-25.495-11.417-25.495-25.505 0-14.08 11.416-25.495 25.495-25.495zM153.845 373v99h-38.47v-66.464h-75.53V373h114zm-19.505-70c14.089 0 25.505 11.416 25.505 25.495 0 14.088-11.416 25.505-25.505 25.505-14.079 0-25.495-11.417-25.495-25.505 0-14.08 11.416-25.495 25.495-25.495zM471 368v57h-22.61v-38.267H404V368h67zm-12.003-41c8.288 0 15.003 6.715 15.003 14.997 0 8.287-6.715 15.003-15.003 15.003-8.282 0-14.997-6.716-14.997-15.003 0-8.282 6.715-14.997 14.997-14.997z"
                opacity=".2"
                transform="translate(-38 -1082) translate(39 1083)"
                className={`${anim(10)}`}
              />
              <path
                stroke="#000"
                d="M206 320v57h-22.61v-38.267H139V320h67zm-12.003-41c8.288 0 15.003 6.715 15.003 14.997 0 8.287-6.715 15.003-15.003 15.003-8.282 0-14.997-6.716-14.997-15.003 0-8.282 6.715-14.997 14.997-14.997z"
                opacity=".2"
                transform="translate(-38 -1082) translate(39 1083)"
                className={`${anim(12)}`}
              />
              <path
                stroke="#000"
                d="M262 368v57h-22.61v-38.267H195V368h67zm-12.003-41c8.288 0 15.003 6.715 15.003 14.997 0 8.287-6.715 15.003-15.003 15.003-8.282 0-14.997-6.716-14.997-15.003 0-8.282 6.715-14.997 14.997-14.997z"
                opacity=".2"
                transform="translate(-38 -1082) translate(39 1083)"
                className={`${anim(14)}`}
              />
              <path
                fill="#000"
                d="M321 280v57h-22.61v-38.267H254V280h67zm-12.003-41c8.288 0 15.003 6.715 15.003 14.997 0 8.287-6.715 15.003-15.003 15.003-8.282 0-14.997-6.716-14.997-15.003 0-8.282 6.715-14.997 14.997-14.997zM486 123v29h-11.473v-19.47H452V123h34zm-5.501-21c4.143 0 7.501 3.358 7.501 7.498 0 4.144-3.358 7.502-7.501 7.502-4.141 0-7.499-3.358-7.499-7.502 0-4.14 3.358-7.498 7.499-7.498z"
                opacity=".2"
                transform="translate(-38 -1082) translate(39 1083)"
                className={`${anim(6)}`}
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}
