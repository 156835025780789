import React from "react"

import { Section, Container } from "components/anti/grid/grid"
import { Button } from "components/anti/buttons/buttons"
import { Slider } from "components/anti/slider/slider"
import { Card } from "components/anti/card/card"

import { useScrollAnim } from "src/components/hooks/hooks"

export const HomePracticesAreas = ({ data, list, lang }) => {
  const [trigger, anim] = useScrollAnim()

  const block = data
  return (
    <>
      <Section forwardRef={trigger} className="home-practice-areas">
        <Container className="mw-xl">
          {block.label && <h6 className={`h6 ${anim(1)} `}>{block.label}</h6>}
          <div className="w-lg-900px mb-4">
            {block.title && <h2 className={`h2 ${anim(2)}`}>{block.title}</h2>}
          </div>
          <div className={`w-lg-600px ${anim(3)}`}>
            {block.text && <p>{block.text}</p>}
          </div>
          <div className={`btn-group d-block ${anim(4)}`}>
            <Button variant="link" link={block?.button?.url}>
              {block?.button?.text}
            </Button>
          </div>
        </Container>
      </Section>
      <Slider
        autoplay={true}
        autoplaySpeed={3000}
        infinite={true}
        visibleInitial={true}
        visibleXlDown={true}
        visibleLgDown={true}
        visibleMdDown={true}
        visibleSmDown={true}
        showInitial={4}
        showLgDown={3}
        showMdDown={2}
        showSmDown={1.5}
        arrowsInitial={true}
        arrowsXlDown={true}
        arrowsLgDown={true}
        arrowsMdDown={false}
        arrowsSmDown={false}
        className={`slick-px-0 practice-areas-content`}
      >
        {list.map((item, i) => {
          const dataLang =
            lang === "EN" ? item?.practice : item?.translation?.practice
          const cover = dataLang?.cover
          const status = dataLang?.status?.taxonomy?.slug
          return (
            <div key={i}>
              <Card
                isLink={status === "active" ? true : false}
                variant="overlay"
                titleClassName="h4"
                className={`card-practice-area active-hover mb-0`}
                img={cover?.thumbnail?.sourceUrl}
                imgRatio="r-1-1"
                title={cover?.title}
                link={`/practice-areas/${item?.slug}`}
              />
            </div>
          )
        })}
        {/* {list.map((item, i) => {
          const dataLang =
            lang === "EN" ? item?.practice : item?.translation?.practice
          const cover = dataLang?.cover
          const status = dataLang?.status?.taxonomy?.slug
          if (status === "inactive") {
            return (
              <div key={i}>
                <Card
                  variant="overlay"
                  className="position-relative"
                  img={cover?.image?.sourceUrl}
                  titleClassName="h4"
                  className={`inactive card-practice-area mb-0 ${anim(
                    7 + i,
                    "revealBlockInRight"
                  )}`}
                  imgRatio="r-1-1"
                  title={cover?.title}
                />
              </div>
            )
          }
        })} */}
      </Slider>
    </>
  )
}
