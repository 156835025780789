import React from "react"
import { useScrollAnim } from "src/components/hooks/hooks"

import { Section, Container } from "components/anti/grid/grid"
import { Button } from "components/anti/buttons/buttons"

// import graphic1 from "assets/img/common/graphic_home-about-1.svg"
import graphic2 from "assets/img/common/graphic_home-about-2.svg"
import gfx from "assets/img/common/shift-graphic/graphic1.png"

import { AboutGraphic } from "./svg/about-graphic"

export const HomeAbout = ({ data }) => {
  const [trigger, anim] = useScrollAnim()

  const block = data

  return (
    <Section forwardRef={trigger} className="home-about">
      <Container>
        <div className="row row-5">
          <div className="col-md-4 col-lg-5 mb-sm-down-4 col-bg">
            <AboutGraphic />
          </div>
          <div className="col-md-8 col-lg-7 col-text">
            {block.label && <h6 className={`${anim(1)}`}>{block.label}</h6>}
            {block.title && (
              <h2 className={`mb-3 ${anim(2)}`}>{block.title}</h2>
            )}
            {block.text && <p className={`${anim(3)}`}>{block.text}</p>}
            <div className={`btn-group d-block ${anim(4)}`}>
              <Button
                variant="link"
                link={block?.button?.url}
                className={`${anim(5)}`}
              >
                {block?.button?.text}
              </Button>
            </div>
            <img
              src={graphic2}
              className={`img-fluid graphic-2 ${anim(6)}`}
              alt="About"
            />
          </div>
        </div>
        <div className="graphic-wrapper">
          <img src={gfx} className={`img-gfx d-none d-md-block ${anim(2)}`} />
          <div className={`circle-gradient ${anim(3)}`} />
          <div className="line-wrapper">
            <div className="text-wrapper">
              <p className={`mx-0 text-muted vertical-text ${anim(4)}`}>
                scroll
              </p>
            </div>
            <div className={`vertical-line ${anim(5)}`} />
            <div className={`small-circle ${anim(6)}`} />
          </div>
        </div>
      </Container>
    </Section>
  )
}
